(function(root, factory) {
	if(typeof define === 'function' && define.amd) { define(['angular'], factory); }
	else { factory(angular); }
}(this, (angular) => {

	'use strict';

	(function() {

		angular.module('loading-overlay.templates', []).run(['$templateCache', function($templateCache) {$templateCache.put('loading-overlay/loading-overlay.html','<div class="angular-overlay-container"><div class="message">{{ loadingOverlayController.getCaption() }}</div><i class="fas fa-spinner"></i></div>');}]);

	})();

	(function() {

		angular.module('loading-overlay', ['loading-overlay.templates']);


	})();

	(function() {

		angular.module('loading-overlay')

		.directive('loadingOverlay', ($interval, LoadingOverlayService) => {
			return {
				restrict: 'E',
				require: ['loadingOverlay'],
				link: function(scope, element, attributes, controllers) {
					scope.$watch('loadingOverlayController.isLoading()', (isLoading, wasLoading) => {
						if(LoadingOverlayService.isLoading()) {
							element.removeClass('overlay-hidden');
							element.addClass('overlay-visible');
						}
						else {
							element.removeClass('overlay-visible');
							element.addClass('overlay-hidden');
						}
					});
				},
				controller: function(LoadingOverlayService) {
					const self = this;

					self.isLoading = function() {
						return LoadingOverlayService.isLoading();
					};

					self.getCaption = function() {
						return LoadingOverlayService.getCaption();
					};
				},
				controllerAs: 'loadingOverlayController',
				templateUrl: 'loading-overlay/loading-overlay.html'
			};
		});


	})();

	(function() {

		angular.module('loading-overlay')

		.service('LoadingOverlayService', function($timeout) {
			const self = this;

			let enabled = false;
			let loading = false;
			let started = null;
			let caption = null;
			let defaultCaption = 'Loading';
			let minimumDuration = 250;
			let loadingTimeout = null;

			self.enable = function(text) {
				if(enabled) { return; }

				enabled = true;
				started = new Date();
				caption = defaultCaption;

				if(typeof text === 'string' && text.length > 0) {
					caption = text;
				}

				loadingTimeout = $timeout(() => {
					loading = true;
					loadingTimeout = null;
				}, minimumDuration);
			};

			self.disable = function() {
				if(!enabled) { return; }

				if(loadingTimeout !== null) {
					$timeout.cancel(loadingTimeout);
					loadingTimeout = null;
				}

				enabled = false;
				started = null;
				loading = false;
			};

			self.setEnabled = function(value) {
				const formattedValue = utilities.parseBoolean(value);

				if(utilities.isValid(formattedValue)) {
					enabled = formattedValue;
				}
			};

			self.isEnabled = function() {
				return enabled;
			};

			self.isDisabled = function() {
				return !enabled;
			};

			self.isLoading = function() {
				return loading;
			};

			self.getTimeElapsed = function() {
				if(started === null) { return 0; }

				return new Date() - started;
			};

			self.getCaption = function() {
				return (utilities.isEmptyString(caption) ? defaultCaption : caption) + '...';
			};

			self.getMinimumDuration = function() {
				return minimumDuration;
			};

			self.setMinimumDuration = function(duration) {
				const formattedDuration = utilities.parseInteger(duration);

				if(isNaN(formattedDuration) || formattedDuration < 0) {
					return;
				}

				minimumDuration = formattedDuration;
			};

			self.getDefaultCaption = function() {
				return defaultCaption;
			};

			self.setDefaultCaption = function(caption) {
				if(utilities.isEmptyString(caption)) {
					return;
				}

				defaultCaption = caption;
			};
		});


	})();


}));
