(function(global, factory) {
	typeof exports === 'object' && typeof module !== 'undefined' ? module.exports = factory() :
		typeof define === 'function' && define.amd ? define(factory) :
			(global.versionInformation = factory());
} (this, () => {

	'use strict';

	if(typeof validator === 'undefined' && typeof require !== 'undefined') {
		global.validator = require('validator');
	}

	const versionInformation = { };

	const versionMatchRegExp = /([0-9]\.?)+/;
	const splitVersionRegExp = /[\. \t]+/g;

	versionInformation.generateVersions = function(version, prefix, suffix) {
		if(version === null || typeof version !== 'object' || !Array.isArray(version)) {
			return null;
		}

		const versions = [];
		let value = null;

		for(let i = 0; i < version.length; i++) {
			value = '';

			if(prefix !== null && prefix !== undefined) {
				value += prefix;
			}

			for(let j = 0; j <= i; j++) {
				if(j > 0) {
					value += '_';
				}

				value += version[j];
			}

			if(suffix !== null && suffix !== undefined) {
				value += suffix;
			}

			versions.push(value);
		}

		return versions;
	};

	versionInformation.parseVersion = function(data, trimTrailingZeroes) {
		let value = data;

		if(typeof data === 'number') {
			value = data.toString();
		}

		if(typeof value === 'string') {
			const version = [];

			const result = value.match(/[^. \t]+/g);

			if(result === null || result.length === 0) {
				return null;
			}

			for(let i = 0; i < result.length; i++) {
				if(validator.isInt(result[i])) {
					const part = parseInt(result[i]);

					if(isNaN(part) || part < 0) { continue; }

					version.push(part.toString());
				}
				else {
					version.push(result[i]);
				}
			}

			if(trimTrailingZeroes) {
				while(true) {
					if(version.length <= 1) {
						break;
					}

					if(version[version.length - 1] === '0') {
						version.pop();
					}
					else {
						break;
					}
				}
			}

			return version;
		}

		return null;
	};

	versionInformation.compareVersions = function(v1, v2, throwErrors) {
		if(typeof v1 !== 'string' || v1.length === 0 || typeof v2 !== 'string' || v2.length === 0) {
			if(throwErrors) {
				throw 'Cannot compare non-string or empty versions.';
			}

			return null;
		}

		const version1 = v1.trim();
		const version2 = v2.trim();

		if(version1.length === 0 || version2.length === 0) {
			if(throwErrors) {
				throw 'Cannot compare empty versions.';
			}

			return null;
		}

		if(!version1.match(versionMatchRegExp) || !version2.match(versionMatchRegExp)) {
			if(throwErrors) {
				throw 'Cannot compare improperly formatted versions.';
			}

			return null;
		}

		const v1parts = version1.split(splitVersionRegExp);
		const v2parts = version2.split(splitVersionRegExp);

		let a = null;
		let b = null;
		let index = 0;

		while(true) {
			if(index >= v1parts.length) {
				if(v1parts.length === v2parts.length) { return 0; }

				for(var i = index; i < v2parts.length; i++) {
					b = parseInt(v2parts[i]);

					if(b !== 0) {
						return -1;
					}
				}

				return 0;
			}

			if(index >= v2parts.length) {
				if(v2parts.length === v1parts.length) { return 0; }

				for(var i = index; i < v1parts.length; i++) {
					a = parseInt(v1parts[i]);

					if(a !== 0) {
						return 1;
					}
				}

				return 0;
			}

			a = parseInt(v1parts[index]);
			b = parseInt(v2parts[index]);

			     if(a > b) { return  1; }
			else if(a < b) { return -1; }

			index++;
		}
	};

	return versionInformation;


}));
