(function(root, factory) {
	if(typeof define === 'function' && define.amd) { define(['angular'], factory); }
	else { factory(angular); }
}(this, (angular) => {

	'use strict';

	(function() {

		angular.module('browser-information', [])

		.service('BrowserInformation', function() {
			const self = this;

			const operatingSystems = [
				{
					type: 'android',
					mobile: true
				},
				{
					type: 'ios',
					mobile: true
				},
				{
					type: 'mac',
					mobile: false
				},
				{
					type: 'xbox',
					mobile: false
				},
				{
					type: 'windows',
					mobile: false
				},
				{
					type: 'linux',
					mobile: false
				}
			];

			self.isOperatingSystem = function(operatingSystem, version) {
				if(typeof operatingSystem !== 'string' || operatingSystem.length === 0) { return false; }

				const formattedOperatingSystem = operatingSystem.trim().toLowerCase();

				if(formattedOperatingSystem.length === 0) { return false; }

				const actualOperatingSystem = self.getOperatingSystem();

				if(actualOperatingSystem === null) { return false; }

				const actualOperatingSystemType = actualOperatingSystem.type;

				if(version === undefined || version === null) {
					return formattedOperatingSystem === actualOperatingSystemType;
				}

				const formattedVersion = versionInformation.parseVersion(version);

				if(formattedVersion === null) {
					return false;
				}

				const actualVersion = versionInformation.parseVersion(bowser.osversion);

				if(actualVersion === null) {
					return false;
				}

				if(actualVersion.length > formattedVersion.length) {
					return false;
				}

				for(let i = 0;i < formattedVersion.length; i++) {
					if(formattedVersion[i] != actualVersion[i]) {
						return false;
					}
				}

				return formattedOperatingSystem === actualOperatingSystemType;
			};

			self.isBrowser = function(browserType, version) {
				if(typeof browserType !== 'string' || browserType.length === 0) { return false; }

				const formattedBrowserType = browserType.trim().toLowerCase();

				if(formattedBrowserType.length === 0) { return false; }

				const actualBrowserType = bowser.name.toLowerCase().replace(/[ \t]/g, '-');

				if(version === undefined || version === null) {
					return formattedBrowserType === actualBrowserType;
				}

				const formattedVersion = versionInformation.parseVersion(version);

				if(formattedVersion === null) {
					return false;
				}

				const actualVersion = versionInformation.parseVersion(bowser.version);

				if(actualVersion === null) {
					return false;
				}

				for(let i = 0; i < formattedVersion.length; i++) {
					if(i >= actualVersion.length) { break; }

					if(formattedVersion[i] != actualVersion[i]) {
						return false;
					}
				}

				return formattedBrowserType === actualBrowserType;
			};

			self.getOperatingSystem = function() {
				for(let i = 0; i < operatingSystems.length; i++) {
					if(bowser[operatingSystems[i].type]) {
						return operatingSystems[i];
					}
				}

				return null;
			};

			self.getClass = function() {
				let osVersionClasses = null;
				const osType = self.getOperatingSystem();

				if(osType !== null && bowser.osversion !== undefined) {
					osVersionClasses = versionInformation.generateVersions(versionInformation.parseVersion(bowser.osversion), osType.type);
				}

				const browserType = bowser.name.toLowerCase().replace(/[ \t]/g, '-');
				const browserVersionClasses = versionInformation.generateVersions(versionInformation.parseVersion(bowser.version), browserType);

				return browserType + (browserVersionClasses === null || browserVersionClasses.length === 0 ? '' : ' ' + browserVersionClasses.join(' ')) + (osType === null ? '' : ' ' + osType.type + (osVersionClasses === null || osVersionClasses.length === 0 ? '' : ' ' + osVersionClasses.join(' ')));
			};

			self.isMobile = function() {
				const osType = self.getOperatingSystem();

				if(osType === null) {
					return false;
				}

				return osType.mobile;
			};
		});


	})();


}));
