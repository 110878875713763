/* eslint-disable prefer-arrow-callback */
/* eslint-disable no-undef */
(
	function(global, factory) {

		if(typeof exports === 'object' && typeof module !== 'undefined') {
			module.exports = factory()
		} else {
			if(typeof define === 'function' && define.amd) {
				define(factory);
			} else {
				(global.utilities = factory());
			}
		}

		// typeof exports === 'object' && typeof module !== 'undefined' ? module.exports = factory() :
		// 	typeof define === 'function' && define.amd ? define(factory) :
		// 		(global.utilities = factory());
	}
	(this, function() {

		'use strict';

		if(typeof require !== 'undefined') {
			if(typeof validator === 'undefined') {
				global.validator = require('validator');
			}

			if(typeof dateFormat === 'undefined') {
				global.dateFormat = require('dateformat');
			}

			if(typeof changeCase === 'undefined') {
				global.changeCase = require('change-case');
			}

			if(typeof months === 'undefined') {
				global.months = require('months');
			}
		}

		const utilities = { };

		const leadingSlashRegExp = /^[\/\\]+/;
		const trailingSlashRegExp = /[\/\\]+$/;
		const regularExpressionRegExp = /\s*\/(.*)\/(.*)\s*/;
		const postalCodeRegExp = /[ \t]*([A-Z][0-9][A-Z])[_\- \t]?([0-9][A-Z][0-9])[ \t]*/i;
		const emailRegExp = /([^+@]+)(\+.*)?(@.+\..+)/;
		const emailDomainRegExp = /([^+@]+)(\+.*)?@(.+\..+)/;
		const youTubeLinkRegExp = /.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#\&\?]*).*/i;
		const youTubeVideoIDRegExp = /[A-Z0-9_-]{11,}/i;
		const lowerCaseWords = ['A', 'An', 'The', 'And', 'But', 'Or', 'For', 'Nor', 'As', 'At', 'By', 'For', 'From', 'In', 'Into', 'Near', 'Of', 'On', 'Onto', 'To', 'With'];
		const upperCaseWords = ['Id', 'Tv', 'Atm'];

		utilities.isValid = function(value) {
			return value !== undefined && value !== null;
		};

		utilities.isInvalid = function(value) {
			return value === undefined || value === null;
		};

		utilities.isBoolean = function(value, allowObjects) {
			return value === true || value === false || (!!allowObjects && value instanceof Boolean);
		};

		utilities.isValidNumber = function(n) {
			return typeof n === 'number' && !isNaN(n) && n !== -Infinity && n !== Infinity;
		};

		utilities.isInvalidNumber = function(n) {
			return typeof n !== 'number' || isNaN(n) || n === -Infinity || n === Infinity;
		};

		utilities.isEmptyString = function(value, trim) {
			return typeof value !== 'string' || (utilities.parseBoolean(trim, true) ? value.trim().length === 0 : value.length === 0);
		};

		utilities.isNonEmptyString = function(value, trim) {
			return typeof value === 'string' && (utilities.parseBoolean(trim, true) ? value.trim().length !== 0 : value.length !== 0);
		};

		utilities.isEmptyArray = function(value) {
			if(utilities.isInvalid(value)) {
				return true;
			}

			if(!Array.isArray(value)) {
				return false;
			}

			return value.length === 0;
		};

		utilities.isNonEmptyArray = function(data) {
			return Array.isArray(data) && data.length !== 0;
		};

		utilities.isObject = function(value, strict) {
			return value !== undefined && (strict ? value !== null && value.constructor === Object : value instanceof Object && !(value instanceof Function));
		};

		utilities.isObjectStrict = function(value) {
			return value !== undefined && value !== null && value.constructor === Object;
		};

		utilities.isEmptyObject = function(value) {
			return value !== undefined && value !== null && value.constructor === Object && Object.keys(value).length === 0;
		};

		utilities.isNonEmptyObject = function(value) {
			return value !== undefined && value !== null && value.constructor === Object && Object.keys(value).length !== 0;
		};

		utilities.isFunction = function(func) {
			return func instanceof Function;
		};

		utilities.isRegularExpression = function(regExp) {
			return regExp instanceof RegExp;
		};

		utilities.isComment = function(data, comment) {
			if(utilities.isEmptyString(data)) { return false; }

			if(utilities.isEmptyString(comment)) {
				comment = '//';
			}

			let commentStartIndex = -1;

			for(let i = 0; i < data.length; i++) {
				if(data[i] === ' ' || data[i] == '\t') { continue; }

				if(data[i] == comment[0]) {
					commentStartIndex = i;
					break;
				}
				else {
					return false;
				}
			}

			if(commentStartIndex < 0 || data.length - commentStartIndex < comment.length) { return false; }

			for(let i = commentStartIndex; i < data.length; i++) {
				if(i - commentStartIndex >= comment.length) { break; }

				if(data[i] != comment[i - commentStartIndex]) {
					return false;
				}
			}

			return true;
		};

		utilities.isVisible = function(element) {
			if(!utilities.isObject(element)) {
				return false;
			}

			if(utilities.isFunction(element.visible)) {
				return element.visible();
			}
			else if(utilities.isBoolean(element.visible)) {
				return element.visible;
			}
			else if(utilities.isFunction(element.hidden)) {
				return !element.hidden();
			}
			else if(utilities.isBoolean(element.hidden)) {
				return !element.hidden;
			}

			return true;
		};

		utilities.isHidden = function(element) {
			if(!utilities.isObject(element)) {
				return true;
			}

			if(utilities.isFunction(element.visible)) {
				return !element.visible();
			}
			else if(utilities.isBoolean(element.visible)) {
				return !element.visible;
			}
			else if(utilities.isFunction(element.hidden)) {
				return element.hidden();
			}
			else if(utilities.isBoolean(element.hidden)) {
				return element.hidden;
			}

			return false;
		};

		utilities.isEnabled = function(element) {
			if(!utilities.isObject(element)) {
				return false;
			}

			if(utilities.isFunction(element.enabled)) {
				return element.enabled();
			}
			else if(utilities.isBoolean(element.enabled)) {
				return element.enabled;
			}
			else if(utilities.isFunction(element.disabled)) {
				return !element.disabled();
			}
			else if(utilities.isBoolean(element.disabled)) {
				return !element.disabled;
			}

			return true;
		};

		utilities.isDisabled = function(element) {
			if(!utilities.isObject(element)) {
				return true;
			}

			if(utilities.isFunction(element.enabled)) {
				return !element.enabled();
			}
			else if(utilities.isBoolean(element.enabled)) {
				return !element.enabled;
			}
			else if(utilities.isFunction(element.disabled)) {
				return element.disabled();
			}
			else if(utilities.isBoolean(element.disabled)) {
				return element.disabled;
			}

			return false;
		};

		utilities.parseBoolean = function(value, defaultValue) {
			if(utilities.isBoolean(value)) { return value; }

			if(utilities.isBoolean(value, true)) { return value.valueOf(); }

			if(!utilities.isBoolean(defaultValue)) {
				defaultValue = null;
			}

			if(value === undefined || value === null) { return defaultValue; }

			if(value === 0) { return false; }

			if(value === 1) { return true; }

			if(typeof value !== 'string') { return defaultValue; }

			const temp = value.trim().toLowerCase();

			if(temp.length === 0) { return defaultValue; }

			if(temp.length === 1) {
			     if(temp.charAt(0) === 't' || temp.charAt(0) === 'y') { return true; }
				else if(temp.charAt(0) === 'f' || temp.charAt(0) === 'n') { return false; }
				else if(temp.charAt(0) === '0') { return false; }
				else if(temp.charAt(0) === '1') { return true; }
				return defaultValue;
			}
			else {
			     if(temp === 'true' || temp === 'yes' || temp === 'on') { return true; }
				else if(temp === 'false' || temp === 'no' || temp === 'off') { return false; }
				return defaultValue;
			}
		};

		utilities.parseInteger = function(value, defaultValue) {
			let newValue = NaN;

			if(typeof value === 'number') {
				newValue = parseInt(value);
			}
			else if(typeof value === 'string') {
				if(validator.isFloat(value)) {
					newValue = parseInt(value);
				}
			}

			if(isNaN(newValue) && typeof defaultValue === 'number') {
				return parseInt(defaultValue);
			}

			return newValue;
		};

		utilities.parseFloatingPointNumber = function(value, defaultValue) {
			let newValue = NaN;

			if(typeof value === 'number') {
				newValue = value;
			}
			else if(typeof value === 'string') {
				if(validator.isFloat(value)) {
					newValue = parseFloat(value);
				}
			}

			if(isNaN(newValue) && typeof defaultValue === 'number') {
				return defaultValue;
			}

			return newValue;
		};

		utilities.parseDate = function(value) {
			if(typeof value === 'number') {
				if(isNaN(value)) { return null; }

				const formattedValue = Math.floor(value);

				return new Date(formattedValue);
			}
			else if(typeof value === 'string') {
				const formattedValue = value.trim();

				if(formattedValue.length === 0) { return null; }

				let timestamp = null;

				if(validator.isInt(formattedValue)) {
					timestamp = parseInt(formattedValue);
				}
				else {
					timestamp = Date.parse(formattedValue);
				}

				if(isNaN(timestamp)) { return null; }

				return new Date(timestamp);
			}
			else if(value instanceof Date) {
				return value;
			}

			return null;
		};

		utilities.parsePostalCode = function(value) {
			if(utilities.isEmptyString(value)) {
				return null;
			}

			const postalCodeData = value.match(postalCodeRegExp);

			if(!postalCodeData) {
				return null;
			}

			return postalCodeData[1] + postalCodeData[2];
		};

		utilities.parseEmail = function(value) {
			if(utilities.isEmptyString(value)) {
				return null;
			}

			const trimmedEmail = value.trim().toLowerCase();

			const data = trimmedEmail.match(emailRegExp);

			if(data === null || data === undefined || data.length < 4) { return null; }

			return data[1] + data[3];
		};

		utilities.parseEmailDomain = function(value) {
			if(utilities.isEmptyString(value)) {
				return null;
			}

			const trimmedEmail = value.trim().toLowerCase();

			const data = trimmedEmail.match(emailDomainRegExp);

			if(data === null || data === undefined || data.length < 4) { return null; }

			return data[3];
		};

		utilities.parseStringList = function(data) {
			if(typeof data !== 'string') { return null; }

			if(data.length === 0) { return []; }

			const list = data.split(/[;,]+/);

			const formattedList = [];

			let formattedValue = null;

			for(let i = 0; i < list.length; i++) {
				formattedValue = list[i].trim();

				if(formattedValue.length === 0) { continue; }

				formattedList.push(formattedValue);
			}

			return formattedList;
		};

		utilities.parseRegularExpression = function(data) {
			if(utilities.isEmptyString(data)) {
				return null;
			}

			const formattedData = data.match(regularExpressionRegExp);

			if(!formattedData) {
				return null;
			}

			return new RegExp(formattedData[1], formattedData[2]);
		};

		utilities.parseYouTubeLink = function(data) {
			if(utilities.isEmptyString(data)) { return null; }

			const formattedData = data.trim();

			const match = formattedData.match(youTubeLinkRegExp);

			if(match && match[1].length >= 11) {
				return match[1];
			}

			if(formattedData.match(youTubeVideoIDRegExp)) {
				return formattedData;
			}

			return null;
		};

		utilities.formatDate = function(date) {
			const formattedDate = utilities.parseDate(date);

			if(formattedDate === null) { return null; }

			return dateFormat(formattedDate, 'dddd mmmm dS, yyyy h:MM:ss TT');
		};

		utilities.formatSimpleDate = function(date, options) {
			const formattedDate = utilities.parseDate(date);

			if(formattedDate === null) { return null; }

			let comma = null;

			if(utilities.isObject(options)) {
				comma = utilities.parseBoolean(options.comma);
			}

			if(comma === null) {
				comma = true;
			}

			return months.getMonth(formattedDate).name + ' ' + formattedDate.getDate() + (comma ? ',' : '') + ' ' + formattedDate.getFullYear();
		};

		utilities.formatStringList = function(data) {
			let list = null;

			if(utilities.isNonEmptyString(data)) {
				list = utilities.parseStringList(data);
			}
			else if(utilities.isNonEmptyArray(data)) {
				list = data;
			}
			else if(typeof data === 'string' || Array.isArray(data)) {
				return '';
			}
			else {
				return null;
			}

			data = '';

			let formattedValue = null;

			for(let i = 0; i < list.length; i++) {
				formattedValue = list[i];

				if(typeof formattedValue === 'string') {
					formattedValue = formattedValue.trim();

					if(formattedValue.length === 0) {
						continue;
					}
				}

				if(data.length > 0) {
					data += ', ';
				}

				data += formattedValue;
			}

			return data;
		};

		utilities.formatMoney = function(value, isDollars, displayCentsIfZero) {
			let money = value;

			if(typeof value === 'string') {
				money = utilities.parseFloatingPointNumber(value.replace(/\$/, ''));
			}

			if(typeof money !== 'number' || isNaN(money) || money < 0) {
				return null;
			}

			if(isDollars !== true) {
				money /= 100;
			}

			const cents = '.' + (money % 1).toFixed(2).substring(2);

			return '$' + Math.floor(money).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + (displayCentsIfZero !== true || cents !== '.00' ? cents : '');
		};

		utilities.formatObject = function(object, format, removeExtra, throwErrors, copyUnsupported, preprocessor, postprocessor) {
			let formattedOptions = removeExtra;

			if(!utilities.isObject(removeExtra)) {
				formattedOptions = {
					removeExtra: !!removeExtra,
					throwErrors: !!throwErrors,
					copyUnsupported: !!copyUnsupported,
					preprocessor: preprocessor,
					postprocessor: postprocessor
				};
			}

			formattedOptions.verbose = utilities.parseBoolean(formattedOptions.verbose, true);
			formattedOptions.order = utilities.parseBoolean(formattedOptions.order, false);

			if(utilities.isFunction(formattedOptions.preprocessor)) {
				if(formattedOptions.throwErrors) {
					object = formattedOptions.preprocessor(object, format, formattedOptions);
				}
				else {
					try {
						object = formattedOptions.preprocessor(object, format, formattedOptions);
					}
					catch(error) {
						if(formattedOptions.verbose) {
							console.error(error);
						}

						return null;
					}
				}
			}

			if(!utilities.isObject(object)) { return { }; }

			if(!utilities.isObject(format)) { return utilities.clone(object, !formattedOptions.copyUnsupported); }

			const formatOptions = utilities.clone(format, !formattedOptions.copyUnsupported);

			let formatAttribute = null;
			const formatAttributes = Object.keys(formatOptions);

			if(formatAttributes.length === 0) { return utilities.clone(object, !formattedOptions.copyUnsupported); }

			for(let i = 0; i < formatAttributes.length; i++) {
				formatAttribute = formatOptions[formatAttributes[i]];

				let errorMessage = null;

				if(!utilities.isObjectStrict(formatAttribute)) {
					errorMessage = 'expected object - received ' + (typeof formatAttribute) + '.';
				}
				else if(utilities.isEmptyString(formatAttribute.type)) {
					errorMessage = 'missing or invalid required type option - expected non-empty string.';
				}
				else if(formatAttribute.subtype !== undefined && typeof formatAttribute.subtype !== 'string') {
					errorMessage = 'subtype option must be a string.';
				}
				else if(formatAttribute.type === 'string' && (formatAttribute.trim !== undefined && !utilities.isBoolean(formatAttribute.trim))) {
					errorMessage = 'optional string trim option must be a boolean value.';
				}
				else if(formatAttribute.type === 'string' && (formatAttribute.case !== undefined && typeof formatAttribute.case !== 'string')) {
					errorMessage = 'optional string case option must be a string value.';
				}
				else if(formatAttribute.type === 'string' && (formatAttribute.nonEmpty !== undefined && !utilities.isBoolean(formatAttribute.nonEmpty))) {
					errorMessage = 'optional string nonEmpty option must be a boolean value.';
				}
				else if(formatAttribute.type === 'object' && (formatAttribute.strict !== undefined && !utilities.isBoolean(formatAttribute.strict))) {
					errorMessage = 'optional object strict option must be a boolean value.';
				}
				else if(formatAttribute.type === 'object' && (formatAttribute.nonEmpty !== undefined && !utilities.isBoolean(formatAttribute.nonEmpty))) {
					errorMessage = 'optional object nonEmpty option must be a boolean value.';
				}
				else if(formatAttribute.type === 'object' && (formatAttribute.format !== undefined && !utilities.isObjectStrict(formatAttribute.format))) {
					errorMessage = 'optional object format option must be an object value.';
				}
				else if(formatAttribute.type === 'array' && (formatAttribute.nonEmpty !== undefined && !utilities.isBoolean(formatAttribute.nonEmpty))) {
					errorMessage = 'optional array nonEmpty option must be a boolean value.';
				}
				else if(formatAttribute.type === 'array' && (formatAttribute.format !== undefined && !utilities.isObjectStrict(formatAttribute.format))) {
					errorMessage = 'optional array format option must be an object value.';
				}
				else if(formatAttribute.required !== undefined && !utilities.isBoolean(formatAttribute.required)) {
					errorMessage = 'optional required format option must be a boolean value.';
				}
				else if(formatAttribute.nullable !== undefined && !utilities.isBoolean(formatAttribute.nullable)) {
					errorMessage = 'optional nullable format option must be a boolean value.';
				}
				else if(formatAttribute.validator !== undefined && !utilities.isFunction(formatAttribute.validator)) {
					errorMessage = 'optional validator format option must be a function.';
				}
				else if(formatAttribute.parser !== undefined && !utilities.isFunction(formatAttribute.parser)) {
					errorMessage = 'optional parser format option must be a function.';
				}
				else if(formatAttribute.formatter !== undefined && !utilities.isFunction(formatAttribute.formatter)) {
					errorMessage = 'optional formatter format option must be a function.';
				}

				if(utilities.isNonEmptyString(errorMessage)) {
					const message = 'Invalid object format parameters for "' + formatAttributes[i] + '" attribute: ' + errorMessage;

					if(formattedOptions.throwErrors) {
						throw new Error(message);
					}

					if(formattedOptions.verbose) {
						console.error(message);
					}

					return null;
				}

				if(formatAttribute.type === 'number') {
					if(typeof formatAttribute.subtype === 'string') {
						formatAttribute.subtype = formatAttribute.subtype.trim().toLowerCase();
					}
					else {
						formatAttribute.subtype = 'float';
					}
				}
				else if(formatAttribute.type === 'string') {
					if(typeof formatAttribute.case === 'string') {
						formatAttribute.case = formatAttribute.case.trim().toLowerCase();
					}
				}
			}

			let formattedObject = { };
			let attribute = null;
			let value = null;
			let formattedValue = null;
			const attributes = Object.keys(object);

			for(let i = 0; i < attributes.length; i++) {
				attribute = attributes[i];
				value = object[attribute];
				formattedValue = null;
				formatAttribute = formatOptions[attribute];

				if(formatAttribute === undefined) {
					if(!formattedOptions.removeExtra) {
						formattedObject[attribute] = utilities.clone(value, !formattedOptions.copyUnsupported);
					}

					continue;
				}

				if(utilities.isFunction(formatAttribute.parser)) {
					if(formattedOptions.throwErrors) {
						value = formatAttribute.parser(value, format, formattedOptions);
					}
					else {
						try {
							value = formatAttribute.parser(value, format, formattedOptions);
						}
						catch(error) {
							if(formattedOptions.verbose) {
								console.error(error);
							}

							return null;
						}
					}
				}

				if(value === undefined) {
					if(formatAttribute.default !== undefined) {
						formattedObject[attribute] = formatAttribute.default;
					}

					continue;
				}
				else if(value === null) {
					if(formatAttribute.nullable) {
						formattedObject[attribute] = null;

						continue;
					}
					else if(formatAttribute.default !== undefined) {
						formattedObject[attribute] = formatAttribute.default;

						continue;
					}
					else {
						const message = attribute + ' attribute cannot be null!';

						if(formattedOptions.throwErrors) {
							throw new Error(message);
						}

						if(formattedOptions.verbose) {
							console.error(message);
						}

						return null;
					}
				}
				else if(formatAttribute.type === 'object') {
					let subObject = null;

					if(typeof value === 'string') {
						try {
							subObject = JSON.parse(value);
						}
						catch(error) {
							const message = 'Invalid stringified JSON data for "' + attribute + '" attribute: ' + error.message;

							if(formattedOptions.throwErrors) {
								throw new Error(message);
							}

							if(formattedOptions.verbose) {
								console.error(message);
							}

							return null;
						}
					}
					else if(utilities.isObject(value, formatAttribute.strict)) {
						subObject = utilities.clone(value, !formattedOptions.copyUnsupported);
					}
					else {
						const message = 'Type mismatch for "' + attribute + '" attribute, expected ' + formatAttribute.type + ' - received: ' + (typeof subObject) + '.';

						if(formattedOptions.throwErrors) {
							throw new Error(message);
						}

						if(formattedOptions.verbose) {
							console.error(message);
						}

						return null;
					}

					if(utilities.isObjectStrict(formatAttribute.format)) {
						const subOptions = utilities.clone(formattedOptions);

						delete subOptions.preprocessor;
						delete subOptions.postprocessor;

						formattedValue = utilities.formatObject(subObject, formatAttribute.format, subOptions);

						if(utilities.isInvalid(formattedValue)) {
							return null;
						}
					}
					else {
						formattedValue = subObject;
					}
				}
				else if(typeof value !== formatAttribute.type) {
					if(typeof value === 'string' && formatAttribute.type === 'number') {
						if(formatAttribute.subtype === 'integer') {
							formattedValue = utilities.parseInteger(value);

							if(isNaN(formattedValue)) {
								const message = 'Invalid ' + formatAttribute.subtype + ' ' + formatAttribute.type + ' for ' + attribute + ' attribute: "' + value + '".';

								if(formattedOptions.throwErrors) {
									throw new Error(message);
								}

								if(formattedOptions.verbose) {
									console.error(message);
								}

								return null;
							}
						}
						else if(formatAttribute.subtype === 'float') {
							formattedValue = utilities.parseFloatingPointNumber(value);

							if(isNaN(formattedValue)) {
								const message = 'Invalid ' + formatAttribute.subtype + ' ' + formatAttribute.type + ' for "' + attribute + '" attribute: "' + value + '".';

								if(formattedOptions.throwErrors) {
									throw new Error(message);
								}

								if(formattedOptions.verbose) {
									console.error(message);
								}

								return null;
							}
						}
						else {
							const message = 'Missing or invalid subtype in format options for "' + attribute + '" number attribute: ' + formatAttribute.subtype + ' - expected integer or float.';

							if(formattedOptions.throwErrors) {
								throw new Error(message);
							}

							if(formattedOptions.verbose) {
								console.error(message);
							}

							return null;
						}
					}
					else if(formatAttribute.type === 'boolean') {
						formattedValue = utilities.parseBoolean(value);

						if(formattedValue === null) {
							const message = 'Invalid boolean value for "' + attribute + '" attribute: "' + value + '"';

							if(formattedOptions.throwErrors) {
								throw new Error(message);
							}

							if(formattedOptions.verbose) {
								console.error(message);
							}

							return null;
						}
					}
					else if(formatAttribute.type === 'date') {
						formattedValue = utilities.parseDate(value);
					}
					else if(formatAttribute.type === 'array') {
						let array = null;

						if(typeof value === 'string') {
							try {
								array = JSON.parse(value);
							}
							catch(error) {
								const message = 'Invalid stringified JSON array for "' + attribute + '" attribute: ' + error.message;

								if(formattedOptions.throwErrors) {
									throw new Error(message);
								}

								if(formattedOptions.verbose) {
									console.error(message);
								}

								return null;
							}
						}
						else {
							array = value;
						}

						if(Array.isArray(array)) {
							formattedValue = [];

							const subOptions = utilities.clone(formattedOptions);

							delete subOptions.preprocessor;
							delete subOptions.postprocessor;

							if(utilities.isObjectStrict(formatAttribute.format)) {
								for(let j = 0; j < array.length; j++) {
									if(array[j] === undefined) {
										continue;
									}

									const temp = utilities.formatObject({ arrayElement: array[j] }, { arrayElement: formatAttribute.format }, subOptions);

									if(utilities.isInvalid(temp)) {
										return null;
									}

									formattedValue.push(temp.arrayElement);
								}
							}
							else {
								for(let j = 0; j < array.length; j++) {
									if(array[j] === undefined) {
										continue;
									}

									formattedValue.push(utilities.clone(array[j], !formattedOptions.copyUnsupported));
								}
							}
						}
						else {
							const message = 'Type mismatch for "' + attribute + '" attribute, expected ' + formatAttribute.type + ' - received: ' + (typeof array) + '.';

							if(formattedOptions.throwErrors) {
								throw new Error(message);
							}

							if(formattedOptions.verbose) {
								console.error(message);
							}

							return null;
						}
					}
					else if(formatAttribute.type === 'string') {
						formattedValue = value.toString();
					}
					else {
						const message = 'Type mismatch for "' + attribute + '" attribute, expected ' + (utilities.isNonEmptyString(formatAttribute.subtype) ? formatAttribute.subtype + ' ' : '') + formatAttribute.type + ' - received: ' + (typeof value) + '.';

						if(formattedOptions.throwErrors) {
							throw new Error(message);
						}

						if(formattedOptions.verbose) {
							console.error(message);
						}

						return null;
					}
				}
				else {
					if(formatAttribute.type === 'number') {
						if(formatAttribute.subtype === 'integer') {
							formattedValue = utilities.parseInteger(value);
						}
						else {
							formattedValue = utilities.parseFloatingPointNumber(value);
						}

						if(isNaN(formattedValue)) {
							const message = 'Invalid number specified for "' + attribute + '", expected integer.';

							if(formattedOptions.throwErrors) {
								throw new Error(message);
							}

							if(formattedOptions.verbose) {
								console.error(message);
							}

							return null;
						}
					}
					else {
						formattedValue = utilities.clone(value, !formattedOptions.copyUnsupported);
					}
				}

				if(formatAttribute.type === 'string' && typeof formattedValue === 'string') {
					if(formatAttribute.trim) {
						formattedValue = formattedValue.trim();
					}

					if(formatAttribute.case !== undefined) {
						if(formatAttribute.case === 'lower') {
							formattedValue = formattedValue.toLowerCase();
						}
						else if(formatAttribute.case === 'upper') {
							formattedValue = formattedValue.toUpperCase();
						}
						else if(formatAttribute.case === 'proper') {
							formattedValue = utilities.toProperCase(formattedValue);
						}
						else if(formatAttribute.case === 'camel') {
							formattedValue = utilities.toCamelCase(formattedValue);
						}
						else {
							const caseTypes = { };
							let caseAttributeData = null;
							let caseAttribute = null;
							const caseAttributes = Object.keys(changeCase);

							for(let j = 0; j < caseAttributes.length; j++) {
								caseAttributeData = caseAttributes[j].match(/^(([A-Z]+)Case)|([A-Z]+First)$/i);

								if(!caseAttributeData) { continue; }

								for(let k = caseAttributeData.length - 1; k >= 0; k--) {
									if(!utilities.isEmptyString(caseAttributeData[k])) {
										caseAttribute = caseAttributeData[k];
										break;
									}
								}

								if(caseAttribute.startsWith('is')) { continue; }

								caseTypes[caseAttribute.toLowerCase()] = caseAttribute;
							}

							const changeCaseFunction = changeCase[caseTypes[formatAttribute.case]];

							if(utilities.isFunction(changeCaseFunction)) {
								formattedValue = changeCaseFunction(formattedValue);
							}
							else {
								const message = 'Missing or invalid case value in format options for "' + attribute + '" string attribute: ' + formatAttribute.case + ' - expected lower or upper.';

								if(formattedOptions.throwErrors) {
									throw new Error(message);
								}

								return null;
							}
						}
					}
				}

				if(utilities.isFunction(formatAttribute.validator)) {
					if(formattedOptions.throwErrors) {
						if(!formatAttribute.validator(formattedValue, format, formattedOptions)) {
							const message = 'Validation check failed for "' + attribute + '" attribute!';

							if(formattedOptions.throwErrors) {
								throw new Error(message);
							}

							if(formattedOptions.verbose) {
								console.error(message);
							}

							return null;
						}
					}
					else {
						try {
							if(!formatAttribute.validator(formattedValue, format, formattedOptions)) {
								const message = 'Validation check failed for "' + attribute + '" attribute!';

								if(formattedOptions.throwErrors) {
									throw new Error(message);
								}

								if(formattedOptions.verbose) {
									console.error(message);
								}

								return null;
							}
						}
						catch(error) {
							if(formattedOptions.verbose) {
								console.error(error);
							}

							return null;
						}
					}
				}

				if(formatAttribute.nonEmpty === true) {
					if((formatAttribute.type === 'string' || formatAttribute.type === 'array') && utilities.isValid(formattedValue) && formattedValue.length === 0) {
						const message = '"' + attribute + '" attribute cannot be empty!';

						if(formattedOptions.throwErrors) {
							throw new Error(message);
						}

						if(formattedOptions.verbose) {
							console.error(message);
						}

						return null;
					}
					else if(formatAttribute.type === 'object' && utilities.isValid(formattedValue) && !utilities.isNonEmptyObject(formattedValue)) {
						const message = '"' + attribute + '" object attribute cannot be empty!';

						if(formattedOptions.throwErrors) {
							throw new Error(message);
						}

						if(formattedOptions.verbose) {
							console.error(message);
						}

						return null;
					}
				}

				if(utilities.isFunction(formatAttribute.formatter)) {
					if(formattedOptions.throwErrors) {
						formattedValue = formatAttribute.formatter(formattedValue, format, formattedOptions);
					}
					else {
						try {
							formattedValue = formatAttribute.formatter(formattedValue, format, formattedOptions);
						}
						catch(error) {
							if(formattedOptions.verbose) {
								console.error(error);
							}

							return null;
						}
					}
				}

				formattedObject[attribute] = formattedValue;
			}

			for(let i = 0; i < formatAttributes.length; i++) {
				attribute = formatAttributes[i];
				formatAttribute = formatOptions[attribute];

				if(formattedObject[attribute] === undefined) {
					if(formatAttribute.required) {
						const message = 'Missing required "' + attribute + '" attribute!';

						if(formattedOptions.throwErrors) {
							throw new Error(message);
						}

						if(formattedOptions.verbose) {
							console.error(message);
						}

						return null;
					}

					if(formatAttribute.default !== undefined) {
						formattedObject[attribute] = utilities.clone(formatAttribute.default, !formattedOptions.copyUnsupported);
					}
				}
			}

			if(formattedOptions.order) {
				const orderedObject = { };

				for(let i = 0; i < formatAttributes.length; i++) {
					attribute = formatAttributes[i];

					if(formattedObject[attribute] !== undefined) {
						orderedObject[attribute] = formattedObject[attribute];

						delete formattedObject[attribute];
					}
				}

				const extraAttributes = Object.keys(formattedObject);

				for(let i = 0; i < extraAttributes.length; i++) {
					attribute = extraAttributes[i];

					orderedObject[attribute] = formattedObject[attribute];
				}

				formattedObject = orderedObject;
			}

			if(utilities.isFunction(formattedOptions.postprocessor)) {
				if(formattedOptions.throwErrors) {
					formattedObject = formattedOptions.postprocessor(formattedObject, format, formattedOptions);
				}
				else {
					try {
						formattedObject = formattedOptions.postprocessor(formattedObject, format, formattedOptions);
					}
					catch(error) {
						if(formattedOptions.verbose) {
							console.error(error);
						}

						return null;
					}
				}
			}

			return formattedObject;
		};

		utilities.formatMessage = function(message) {
			if(utilities.isInvalid(message)) {
				return message;
			}

			let formattedMessage = message;

			if(utilities.isObject(message)) {
				if(Array.isArray(message)) {
					formattedMessage = [];

					for(let i = 0; i < message.length; i++) {
						formattedMessage.push(utilities.formatMessage(message[i]));
					}

					return formattedMessage;
				}
				else if(message instanceof Date) {
					return message.toString();
				}
				else {
					if(utilities.isValid(message.details)) {
						if(utilities.isObject(message.details)) {
							if(utilities.isObject(message.details.messages)) {
								const messages = message.details.messages;
								const messageTypes = Object.keys(messages);

								formattedMessage = [];

								for(let i = 0; i < messageTypes.length; i++) {
									if(utilities.isNonEmptyArray(messages[messageTypes[i]])) {
										for(let j = 0; j < messages[messageTypes[i]].length; j++) {
											formattedMessage.push(utilities.formatMessage(((messages[messageTypes[i]][j].toLowerCase().startsWith(messageTypes[i].toLowerCase())) ? '' : messageTypes[i] + ' ') + messages[messageTypes[i]][j]));
										}
									}
									else {
										formattedMessage.push(utilities.formatMessage(((utilities.toString(messages[messageTypes[i]]).toLowerCase().startsWith(messageTypes[i].toLowerCase())) ? '' : messageTypes[i] + ' ') + utilities.toString(messages[messageTypes[i]])));
									}
								}

								return formattedMessage;
							}
							else {
								if(utilities.isNonEmptyString(message.message)) {
									formattedMessage = message.message;
								}
								else {
									return utilities.toString(message);
								}
							}
						}
						else {
							formattedMessage = utilities.toString(message.details);
						}
					}
					else if(utilities.isNonEmptyString(message.error)) {
						formattedMessage = message.error;
					}
					else if(utilities.isNonEmptyString(message.message)) {
						formattedMessage = message.message;
					}
					else if(utilities.isObject(message.error) && utilities.isNonEmptyString(message.error.message)) {
						formattedMessage = message.error.message;
					}
					else {
						return utilities.toString(message);
					}
				}
			}

			if(typeof formattedMessage !== 'string') {
				formattedMessage = utilities.toString(formattedMessage);
			}

			formattedMessage = formattedMessage.trim();

			if(utilities.isEmptyString(formattedMessage, false) || formattedMessage.length < 2) {
				return formattedMessage;
			}

			formattedMessage = formattedMessage.charAt(0).toUpperCase() + formattedMessage.slice(1);

			const punctuation = formattedMessage.charAt(formattedMessage.length - 1);

			if(punctuation === '.' || punctuation === '!' || punctuation === '?') {
				return formattedMessage;
			}

			return formattedMessage + '.';
		};

		utilities.trimString = function(value, defaultValue) {
			if(typeof value !== 'string') {
				return defaultValue === undefined ? null : defaultValue;
			}

			return value.trim();
		};

		utilities.trimWhitespace = function(string, trimNewlines) {
			if(typeof string !== 'string') { return null; }

			let trimmedString = string.replace(/^[ \t]+|[ \t]+$/gm, '');

			let formattedTrimNewlines = utilities.parseBoolean(trimNewlines);

			if(formattedTrimNewlines === null) {
				formattedTrimNewlines = false;
			}

			if(formattedTrimNewlines) {
				trimmedString = trimmedString.replace(/\r\n?|\n/g, '');
			}

			return trimmedString;
		};

		utilities.replaceNonBreakingSpaces = function(string) {
			if(typeof string !== 'string') {
				return string;
			}

			return string.replace(/&nbsp;/gi, ' ');
		};

		utilities.indentParagraph = function(paragraph, amount, indent, clearEmptyLines) {
			if(typeof paragraph !== 'string') { return null; }

			let formattedClearEmptyLines = utilities.parseBoolean(clearEmptyLines);

			if(formattedClearEmptyLines === null) {
				formattedClearEmptyLines = true;
			}

			let formattedAmount = utilities.parseInteger(amount);

			if(isNaN(formattedAmount) || formattedAmount < 0) {
				formattedAmount = 1;
			}

			let formattedIndent = indent;

			if(typeof formattedIndent !== 'string') {
				formattedIndent = '\t';
			}

			let indentation = '';

			for(var i = 0; i < formattedAmount; i++) {
				indentation += formattedIndent;
			}

			if(!formattedClearEmptyLines) {
				return paragraph.replace(/^/gm, indentation);
			}

			let line = null;
			const lines = paragraph.split(/\r\n?|\n/g);
			let indentedParagraph = '';

			for(var i = 0; i < lines.length; i++) {
				line = lines[i];

				indentedParagraph += (utilities.isEmptyString(line) ? '' : indentation + line) + ((i < lines.length - 1) ? '\n' : '');
			}

			return indentedParagraph;
		};

		utilities.trimLeadingZeroes = function(value) {
			if(typeof value !== 'string') {
				return null;
			}

			if(value.length === 0) {
				return value;
			}

			const formattedValue = value.trim();

			if(formattedValue.length === 0) {
				return formattedValue;
			}

			if(formattedValue.match(/^[0]+$/)) {
				return '0';
			}

			return formattedValue.replace(/^0+/, '');
		};

		utilities.addLeadingZeroes = function(value, expectedLength) {
			if(utilities.isInvalid(value)) { return null; }

			let data = value.toString();

			expectedLength = utilities.parseInteger(expectedLength);

			if(isNaN(expectedLength) || expectedLength < 0) {
				return data;
			}

			const numberOfZeroes = expectedLength - data.length;

			for(let i = 0; i < numberOfZeroes; i++) {
				data = '0' + data;
			}

			return data;
		};

		utilities.toProperCase = function(value, prefix, suffix) {
			const formattedPrefix = typeof prefix === 'string' ? utilities.toProperCase(prefix) : '';
			const formattedSuffix = typeof suffix === 'string' ? utilities.toProperCase(suffix) : '';
			let formattedValue = typeof value === 'string' ? value.trim().replace(/\s+/g, ' ') : '';

			if(formattedPrefix.length !== 0) {
				formattedValue = formattedPrefix + (formattedValue.length === 0 ? '' : ' ' + formattedValue);
			}

			if(formattedSuffix.length !== 0) {
				formattedValue = (formattedValue.length === 0 ? '' : formattedValue + ' ') + formattedSuffix;
			}

			let firstWord = true;

			formattedValue = formattedValue.replace(/([^\W_]+[^\s-]*) */g, (word) => {
				const formattedWord = word.charAt(0).toUpperCase() + word.substr(1).toLowerCase();

				if(!firstWord && !formattedValue.trim().endsWith(word)) {
					for(let i = 0; i < lowerCaseWords.length; i++) {
						if(formattedWord.trim() === lowerCaseWords[i]) {
							firstWord = false;
							return formattedWord.toLowerCase();
						}
					}
				}

				for(let i = 0; i < upperCaseWords.length; i++) {
					if(formattedWord.trim() === upperCaseWords[i]) {
						firstWord = false;
						return formattedWord.toUpperCase();
					}
				}

				firstWord = false;
				return formattedWord;
			});

			return formattedValue.trim();
		};

		utilities.toCamelCase = function(value, prefix, suffix) {
			const formattedPrefix = typeof prefix === 'string' ? utilities.toCamelCase(prefix) : '';
			let formattedSuffix = typeof suffix === 'string' ? utilities.toCamelCase(suffix) : '';

			let formattedValue = '';
			let word = null;
			const words = utilities.isEmptyString(value) ? [] : value.trim().replace(/[^\w\s]/g, '').match(/(\w*)/g);

			for(let i = 0; i < words.length; i++) {
				word = words[i];

				if(word.length === 0) {
					continue;
				}

				formattedValue += word.charAt(0).toUpperCase() + word.substr(1).toLowerCase();
			}

			if(formattedValue.length !== 0 && formattedPrefix.length === 0) {
				formattedValue = formattedValue.charAt(0).toLowerCase() + formattedValue.substr(1);
			}

			if((formattedValue.length !== 0 || formattedPrefix.length !== 0) && formattedSuffix.length !== 0) {
				formattedSuffix = formattedSuffix.charAt(0).toUpperCase() + formattedSuffix.substr(1);
			}

			return formattedPrefix + formattedValue + formattedSuffix;
		};

		utilities.toTitleCase = function(value) {
			if(typeof value !== 'string') {
				return null;
			}

			return value.replace(/\w\S*/g, (text) => {
				return text.charAt(0).toUpperCase() + text.substr(1).toLowerCase();
			});
		};

		utilities.toString = function(value) {
			if(value === undefined) {
				return 'undefined';
			}
			else if(value === null) {
				return 'null';
			}
			else if(typeof value === 'string') {
				return value;
			}
			else if(value === Infinity) {
				return 'Infinity';
			}
			else if(value === -Infinity) {
				return '-Infinity';
			}
			else if(typeof value === 'number' && isNaN(value)) {
				return 'NaN';
			}
			else if(utilities.isFunction(value)) {
				return value.toString();
			}
			else if(value instanceof Date) {
				return value.toString();
			}

			return JSON.stringify(value);
		};

		utilities.compareDates = function(dateA, dateB) {
			const formattedDateA = utilities.parseDate(dateA);
			const formattedDateB = utilities.parseDate(dateB);

			if(formattedDateA === null && formattedDateB === null) {
				return 0;
			}

			if(formattedDateA === null) {
				return -1;
			}

			if(formattedDateB === null) {
				return 1;
			}

			return formattedDateA.getTime() - formattedDateB.getTime();
		};

		utilities.compareCasePercentage = function(text) {
			if(utilities.isEmptyString(text)) { return 0; }

			let upper = 0;
			let lower = 0;

			const lowerA = 'a'.charCodeAt();
			const lowerZ = 'z'.charCodeAt();
			const upperA = 'A'.charCodeAt();
			const upperZ = 'Z'.charCodeAt();

			for(let i = 0; i < text.length; i++) {
				if(text.charCodeAt(i) >= lowerA && text.charCodeAt(i) <= lowerZ) { lower++; }
				else if(text.charCodeAt(i) >= upperA && text.charCodeAt(i) <= upperZ) { upper++; }
			}

			return upper - lower;
		};

		utilities.reverseString = function(data) {
			if(typeof data !== 'string') { return null; }

			let reverse = '';

			for(let i = 0; i < data.length; i++) {
				reverse += data[data.length - i - 1];
			}

			return reverse;
		};

		utilities.createError = function(message, status) {
			const error = new Error(utilities.formatMessage(message));
			error.status = utilities.parseInteger(status, 500);
			return error;
		};

		utilities.clone = function(object, throwUnsupported) {
			const formattedThrowUnsupported = utilities.parseBoolean(throwUnsupported, true);
			let copy;

			if(!utilities.isObject(object)) {
				return object;
			}

			if(object instanceof Date) {
				copy = new Date();
				copy.setTime(object.getTime());

				return copy;
			}

			if(object instanceof Array) {
				copy = [];

				for(let i = 0, length = object.length; i < length; i++) {
					copy[i] = utilities.clone(object[i], formattedThrowUnsupported);
				}

				return copy;
			}

			if(object instanceof Set) {
				return new Set(object);
			}

			if(object instanceof Map) {
				return new Map(object);
			}

			if(typeof Buffer !== 'undefined' && object instanceof Buffer) {
				return new Buffer(object);
			}

			if(object instanceof Object) {
				if(object instanceof Error) {
					copy = new Error(object.message);
				}
				else {
					copy = { };
				}

				for(const attribute in object) {
					if(object.hasOwnProperty(attribute)) {
						copy[attribute] = utilities.clone(object[attribute], formattedThrowUnsupported);
					}
				}

				return copy;
			}

			if(formattedThrowUnsupported) {
				throw new Error('Unable to copy object, type not supported!');
			}
			else {
				return object;
			}
		};

		utilities.merge = function(a, b, copy, deepMerge) {
			if(!utilities.isObject(a) || Array.isArray(a)) {
				return null;
			}

			let formattedCopy = utilities.parseBoolean(copy);

			if(formattedCopy === null) {
				formattedCopy = true;
			}

			let newObject = null;

			if(formattedCopy) {
				newObject = utilities.clone(a);
			}
			else {
				newObject = a;
			}

			if(!utilities.isObject(a) || Array.isArray(a) || !utilities.isObject(b) || Array.isArray(b)) {
				return newObject;
			}

			let formattedDeepMerge = utilities.parseBoolean(deepMerge);

			if(formattedDeepMerge === null) {
				formattedDeepMerge = true;
			}

			let attribute = null;
			let currentValue = null;
			let newValue = null;
			const attributes = Object.keys(b);

			for(let i = 0; i < attributes.length; i++) {
				attribute = attributes[i];
				currentValue = newObject[attribute];
				newValue = utilities.clone(b[attribute]);

				if(formattedDeepMerge && utilities.isObject(currentValue) && !Array.isArray(currentValue) && utilities.isObject(newValue) && !Array.isArray(newValue)) {
					newObject[attribute] = utilities.merge(currentValue, newValue);
				}
				else {
					newObject[attribute] = newValue;
				}
			}

			return newObject;
		};

		utilities.calculateAge = function(date) {
			const currentDate = new Date();

			const formattedDate = utilities.parseDate(date);

			if(formattedDate === null || formattedDate > currentDate) {
				return -1;
			}

			return Math.floor(((currentDate - formattedDate) / 1000 / (60 * 60 * 24)) / 365.25);
		};

		utilities.prependSlash = function(path) {
			if(typeof path !== 'string') { return null; }

			let data = path.trim();

			if(data.length === 0) { return data; }

			if(data[0] !== '/' && data[0] !== '\\') {
				data = '/' + data;
			}

			return data;
		};

		utilities.appendSlash = function(path) {
			if(typeof path !== 'string') { return null; }

			let data = path.trim();

			if(data.length === 0) { return data; }

			if(data[data.length - 1] !== '/' && data[data.length - 1] !== '\\') {
				data += '/';
			}

			return data;
		};

		utilities.joinPaths = function(base, path) {
			const formattedBase = typeof base === 'string' ? base.trim().replace(trailingSlashRegExp, '') : null;
			const formattedPath = typeof path === 'string' ? path.trim().replace(leadingSlashRegExp, '') : null;

			let newPath = '';

			if(utilities.isNonEmptyString(formattedBase)) {
				newPath += formattedBase;

				if(utilities.isNonEmptyString(formattedPath)) {
					newPath += '/';
				}
			}

			if(utilities.isNonEmptyString(formattedPath)) {
				newPath += formattedPath;
			}

			return newPath;
		};

		utilities.createQueryString = function(data, includeQuestionMark) {
			return !utilities.isObject(data) ? '' : (includeQuestionMark === true ? '?' : '') + Object.keys(data).map((key) => {
				return encodeURIComponent(key) + '=' + encodeURIComponent(data[key]);
			}).join('&');
		};

		utilities.createRange = function(start, end) {
			let formattedStart = utilities.parseInteger(start);
			let formattedEnd = utilities.parseInteger(end);

			if(isNaN(formattedEnd)) {
				formattedEnd = formattedStart;
				formattedStart = 0;
			}

			if(isNaN(formattedStart) || isNaN(formattedEnd) || formattedStart > formattedEnd) {
				return [];
			}

			const range = [];

			for(let i = formattedStart; i <= formattedEnd; i++) {
				range.push(i);
			}

			return range;
		};

		utilities.getDateString = function(date) {
			if(!(date instanceof Date)) {
				date = new Date();
			}

			return date.getFullYear() + '-' + (date.getMonth() < 9 ? '0' : '') + (date.getMonth() + 1) + '-' + (date.getDate() < 10 ? '0' : '') + date.getDate() + '_' + (date.getHours() < 10 ? '0' : '') + date.getHours() + '-' + (date.getMinutes() < 10 ? '0' : '') + date.getMinutes() + '-' + (date.getSeconds() < 10 ? '0' : '') + date.getSeconds();
		};

		utilities.futureMonths = function(date) {
			let month = 0;
			const currentDate = new Date();

			if(date.getFullYear() == currentDate.getFullYear()) {
				month = currentDate.getMonth();
			}

			const months = [];

			for(let i = 0; i < 12; i++) {
				if(i >= month) {
					months.push((i <= 8 ? '0' : '') + (i + 1));
				}
			}

			return months;
		};

		utilities.visibleElements = function(elements) {
			if(utilities.isEmptyArray(elements)) {
				return [];
			}

			const visibleElements = [];

			for(let i = 0; i < elements.length; i++) {
				if(utilities.isVisible(elements[i])) {
					visibleElements.push(elements[i]);
				}
			}

			return visibleElements;
		};

		utilities.hiddenElements = function(elements) {
			if(utilities.isEmptyArray(elements)) {
				return [];
			}

			const hiddenElements = [];

			for(let i = 0; i < elements.length; i++) {
				if(utilities.isHidden(elements[i])) {
					hiddenElements.push(elements[i]);
				}
			}

			return hiddenElements;
		};

		utilities.enabledElements = function(elements) {
			if(utilities.isEmptyArray(elements)) {
				return [];
			}

			const enabledElements = [];

			for(let i = 0; i < elements.length; i++) {
				if(utilities.isEnabled(elements[i])) {
					enabledElements.push(elements[i]);
				}
			}

			return enabledElements;
		};

		utilities.disabledElements = function(elements) {
			if(utilities.isEmptyArray(elements)) {
				return [];
			}

			const disabledElements = [];

			for(let i = 0; i < elements.length; i++) {
				if(utilities.isDisabled(elements[i])) {
					disabledElements.push(elements[i]);
				}
			}

			return disabledElements;
		};

		utilities.elementsWithAttribute = function(elements, attribute, hasAttribute) {
			if(!Array.isArray(elements)) { return []; }

			if(!utilities.isBoolean(hasAttribute)) {
				hasAttribute = true;
			}

			if(utilities.isEmptyString(attribute)) {
				return [];
			}

			const formattedAttribute = attribute.trim();

			let element = null;
			const filteredElements = [];

			for(let i = 0; i < elements.length; i++) {
				element = elements[i];

				if(!utilities.isObject(element)) {
					continue;
				}

				if(utilities.isInvalid(elements[i][formattedAttribute])) {
					if(!hasAttribute) {
						filteredElements.push(element);
					}
				}
				else {
					if(hasAttribute) {
						filteredElements.push(elements[i]);
					}
				}
			}

			return filteredElements;
		};

		utilities.elementsWithoutAttribute = function(elements, attribute, hasAttribute) {
			return utilities.elementsWithAttribute(elements, attribute, false);
		};

		utilities.matchAttribute = function(element, attribute, value) {
			if(!utilities.isObject(element)) {
				return false;
			}

			if(utilities.isEmptyString(attribute)) {
				return true;
			}

			return element[attribute] === value;
		};

		return utilities;
	})
);
